import axios from '../utils/server'

/* 分页查询UserAddress记录 */
export const ADRESS_QUERYLIST = (params) => axios.post('/demo/address/queryUserAddress', params)
    /* 新增或者编辑UserAddress记录 */
export const ADRESS_ADDEDIT = (params) => axios.post('/demo/address/addEditUserAddress', params)
    /* 更新UserAddress记录 */
export const ADRESS_UPDATE = (params) => axios.post('/demo/address/update', params)
    /* 根据主键查找UserAddress记录 */
export const ADRESS_FINDUSER = (params) => axios.post('/demo/address/findUserAddress', params)
    /* 删除UserAddress记录 */
export const ADRESS_DELEBYID = (params) => axios.post('/demo/address/deleteById', params)


/* 操作部分 */
// 查询"操作"列表
export const OPERATION_QUERYLIST = (params) => axios.post('/operation/queryOperationLList', params)
    // 创建"操作"记录
export const OPERATION_CREATE = (params) => axios.post('/operation/createOperation', params)
    // 更新 "操作"
export const OPERATION_UPDATE = (params) => axios.post('/operation/updateOperation', params)
    // 查询 列表之"操作"列表
export const OPERATION_QUERYROWLIST = (params) => axios.post('/operation/queryRowOperationList', params)
    // 绑定"操作"到列表
export const OPERATION_BINDROW = (params) => axios.post('/operation/bindRowOperation', params)
    // 绑定"操作"到列表
export const OPERATION_UPDATEROW = (params) => axios.post('/operation/updateRowOperation', params)