<template>
  <div class="container">
    <div style="padding:50px;">
      <!-- 表单 -->
      <header class="header">查询{{formData.formName}}</header>
      <createForm :operations="formData.operations" :refs="formData.formName"
        :list="formData.fields" :inline="formData.inline" :labelWidth="formData.labelWidth"
        :labelPosition="formData.labelPosition" :size="formData.size"
        :formWidth="formData.formWidth">
      </createForm>

      <div style="margin: 20px 30px;">
        <el-button type="primary" @click="submit">提交</el-button>
      </div>

      <!-- 列表部分 -->
      <div class="tabele">
        <tableView ref="childForm" :tableParams="tableData"></tableView>
      </div>

    </div>
  </div>
</template>

<script>
import createForm from "@/components/dynamicForm/createForm";
import tableView from "@/components/tableView";
import { getFormData } from "@/api/form";
import { queryTableList } from "@/api/table";
import { ADRESS_ADDEDIT } from "@/api/user";
export default {
  data() {
    return {
      formData: {},
      tableData: {},
    };
  },
  mounted() {
    this.getForm();
    this.getTable();
  },
  methods: {
    //   获取表单
    async getForm() {
      try {
        const { data: res } = await getFormData({ formId: 90 });
        this.formData = res.data.list[0];
      } catch (err) {
        return err;
      }
    },

    //获取table
    async getTable() {
      try {
        const { data: res } = await queryTableList({ tableId: 60 });
        this.tableData = res.data.list[0];
      } catch (error) {
        console.log(error);
      }
    },

    // 提交
    async submit() {
      let contactMobile = this.formData.fields[0].val;
      let contactAddress = this.formData.fields[1].val;
      let contactDetailAddress = this.formData.fields[2].val;
      if (contactMobile && contactAddress && contactDetailAddress) {
        try {
          const { data: res } = await ADRESS_ADDEDIT({
            contactMobile,
            contactAddress,
            contactDetailAddress,
          });
          if (res.resCode) {
            this.$notify.success({
              title: "提示",
              message: "添加成功",
            });
            this.$refs.childForm.getTableData();
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$notify.warning({
          title: "提示",
          message: "请正确输入表单",
        });
      }
    },
  },
  components: {
    createForm,
    tableView,
  },
};
</script>

<style scoped>
.container {
  width: 1200px;
  height: 100vh;
  margin: 0 auto;
}

.header {
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
}
</style>